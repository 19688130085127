@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Commissioner:wght@400;600&display=swap');

:root {
    --DefaultGreen: #46A758;
    --OnHoverGreen: #47BB5C;
    --PressedGreen: #3C994D;

    --GrassLight2: #F3FCF3;
    --GrassLight3: #EBF9EB;
    --GrassLight5: #CEEBCF;
    --GrassLight8: #65BA75;
    --GrassLight10: #3D9A50;
    --GrassLight11: #297C3B;


    --GrassDark7: #245530;
    --GrassDark8: #2F6E3B;
    --GrassDark9: #46A758;
    --GrassDark10: #55B467;

    --GreenLight10: #299764;

    --ErrorColor: #CA3214;
    --TomatoDark7: #7F2315;
    --TomatoDark8: #A42A12;
    --TomatoDark9: #E54D2E;

    --TextWhite: #fff;

    --SageLight12: #111C18;


    --GrayLight1: #FCFCFC;
    --GrayLight2: #F8F8F8;
    --GrayLight3: #F3F3F3;
    --GrayLight4: #EDEDED;
    --GrayLight5: #E8E8E8;
    --GrayLight6: #E2E2E2;
    --GrayLight7: #DBDBDB;
    --GrayLight8: #C7C7C7;
    --GrayLight9: #8F8F8F;
    --GrayLight10: #858585;
    --GrayLight11: #6F6F6F;
    --GrayLight12: #171717;

    --transition: all 300ms ease-in;

    --blue: #2F52BF;
    --lightBlue: #3F6DFF;
    --lightBlue2: #99AFFF;
    --lightBlue3: #F2F5FF;
    --darkBlue: #2F52BF;


}

* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}


body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #F8F8F8;
}

@media screen and (max-width: 1500px) {
    body {
        zoom: 0.8;
    }
}

a {
    margin: unset;
    padding: unset;
    text-decoration: unset;
    cursor: pointer;
}

a:visited {
    color: black;
}

button {
    margin: unset;
    padding: unset;
    text-decoration: unset;
    border: unset;
    font-family: 'Inter';
    background: none;
    cursor: pointer;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.date-picker {
    width: 100%;
    height: 36px;
    border: 1px solid var(--GrayLight7);
    border-radius: 4px;
    padding: 0px 16px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.date-picker-request {
    width: 100%;
    height: 36px;
    background: var(--GrayLight1);
    border: 1px solid var(--GrayLight3);
    border-radius: 4px;
    padding: 8px 8px 8px 16px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--GrayLight12);
}

.date-picker-new-request {
    width: 100%;
    height: 38px;
    background: var(--GrayLight1);
    border: 1px solid var(--GrayLight3);
    border-radius: 4px;
    padding: 0px 16px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}


.last-dropdown {
    left: unset !important;
}

.pagination-class {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 16px 0px;
    list-style: none;

}

.prev-class {
    padding: 8px 16px;
    background: #FCFCFC;
    border: 1px solid #DBDBDB;
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin-right: 20px;

    /* Gray / Light / 11 */

    color: #6F6F6F;
}

.next-class {
    padding: 8px 16px;
    background: #FCFCFC;
    border: 1px solid #DBDBDB;
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin-left: 20px;

    /* Gray / Light / 11 */

    color: #6F6F6F;
}

.page-class a {
    width: 36px;
    height: 36px;
    background: #FCFCFC;
    border: 1px solid #DBDBDB;
    border-radius: 4px;
    text-align: center;
    padding: 8px;
    color: #6F6F6F;
}

.active-class a {
    background: #EDEDED;
    border: 1px solid #DBDBDB;
    padding: 8px;
    color: #171717;
}

.page-class::marker {
    display: none;
}

.tooltip-custom-class {
    background: #282828 !important;
    max-width: 320px;

    box-shadow: 0px 4px 8px -5px rgba(0, 0, 0, 0.1);
    border-radius: 4px !important;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 20px;


    color: #FFFFFF !important;
    z-index: 102;
}


.disabled-new-request-link{
    pointer-events: none;
}